<template>
  <div>
    <notifications position="top right" group="notify" />
    <router-view></router-view>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapGetters(["user", "role"]),
  },
  async created() {
    const iscookiespresent = document.cookie.indexOf("token=");
    console.log("iscookiepresnt", iscookiespresent);
    if (iscookiespresent == -1) {
      await this.$store.dispatch("user/logout");
      // this.$notify({
      //   group: "notify",
      //   type: "success",
      //   text: "logout successfully",
      // });
      // this.$router.push(`/`);

      // sessionStorage.clear();
    }
  },
  mounted() {
    this.$store.dispatch('location/updateLocation', null);
    setTimeout(() => {
      if (this.user && this.role == "admin") {
        const channel = this.$pusher.subscribe("my-channel");
        console.log("Channel ---------", channel);
        console.log("USER ---- ", this.user);

        channel.bind("my-event-" + this.user.user.id, (data) => {
          console.log("event data-------------", data);
          this.SET_PUSHER_RESPONSE(data.result);
          const res = data.result;
          if (res.success && res.status == "uploaded") {
            this.$notify({
              group: "notify",
              type: "success",
              text: res.message,
              duration: 10000,
            });
          }
          if (!res.success) {
            this.$notify({
              group: "notify",
              type: "error",
              text: res.message,
              duration: 10000,
            });
          }
        });
      }
    }, 2000);
  },

  methods: {
    ...mapMutations("pusher", ["SET_PUSHER_RESPONSE", "RESET_PUSHER_RESPONSE"]),
  },
};

</script>
