import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import user from "./modules/user";
import common from "./modules/common";
import popup from "./modules/popup";
import pusher from './modules/pusher'
import location from './modules/location'
// import path from './modules/path'; // Import the new path module

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
        }),
    ],
    modules: {
        user,
        common,
        popup,
        pusher,
        location,
        // path // Register the path module
    },
    getters,
});

export default store;
