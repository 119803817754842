// store/modules/location.js
const state = {
    currentLocation: '', // String state to store the current location
};

const mutations = {
    SET_LOCATION(state, value) {
        state.currentLocation = value;
    },
};

const actions = {
    updateLocation({ commit }, value) {
        commit('SET_LOCATION', value);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
